
<template>
    <div>
        <b-card no-body>
            <b-card-header class="p-0">
                <b-button
                    size="sm"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    style="padding: .5rem 10px!important"
                    :disabled="isNotSelectedRows"
                >
                    <unicon name="trash-alt" width="18"></unicon>
                </b-button>
            </b-card-header>
            <b-card-body class="px-0">
                <vue-good-table
                    @on-selected-rows-change="selectionChanged"
                    :columns="columns"
                    :rows="activeTableQuestions"
                    :rtl="false"
                    :small="true"
                    styleClass="vgt-table condensed"
                    ref="accounts-table"
                    :select-options="{
                        enabled: true,
                        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        clearSelectionText: 'clear',
                        disableSelectInfo: true, // disable the select info panel on top
                        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }"
                >
                 <template
                    slot="table-row"
                    slot-scope="props"
                >
                    <span v-if="props.column.field === 'question'">
                        {{ props.formattedRow[props.column.field].substr(0, 100) + (props.formattedRow[props.column.field].length > 100 ? '...' : '') }}
                    </span>
                    
                    <span v-else-if="props.column.field === 'dateAsked'">
                        {{ new Date(props.formattedRow[props.column.field]).toISOString().substr(0, 10) }}
                    </span>
                    
                    <span v-else-if="props.column.field === 'action'">
                        <b-button
                            :to="`/lessons/${props.row.lessonId}/${props.row.videoId}/question/un/${props.row.id}`"
                            size="sm"
                            variant="flat-secondary"
                            class="btn-icon rounded-circle"
                            style="padding: 2px 6px!important"
                        >
                            <unicon name="ellipsis-v" width="18"></unicon>
                        </b-button>
                    </span>

                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
                </vue-good-table>
            </b-card-body>
        </b-card>
        <b-col cols="12" class="d-flex justify-content-center">
            <b-pagination
                :value="responderQuestionsFirstPage"
                :total-rows="unAnsweardQuestion.length"
                :per-page="responderQuestionsPageLength"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => updateQuestionsPagination({currentPage: value, type: 0})"
            >
                <template #prev-text>
                    <unicon width="20" name="angle-right" fill="royalblue" />
                </template>
                <template #next-text>
                    <unicon width="20" name="angle-right" fill="royalblue" />
                </template>
            </b-pagination>
        </b-col>
    </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'

export default {
    components: {
        VueGoodTable,
        BPagination,
        BButton,
        BCard,
        BCardHeader,
        BCardBody,
        BCol,
    },
    props: {
    },
    data: () => ({
        isNotSelectedRows: true,
        columns: [
            {
                label: 'اسم الطالب',
                field: 'studentName',
            },
            {
                label: 'اسم المادة',
                field: 'unitName',
            },
            {
                label: 'السؤال',
                field: 'question',
            },
            {
                label: 'التاريخ',
                field: 'dateAsked',
                sortable: false
            },
            {
                label: 'تفاصيل',
                field: 'action',
                sortable: false
            },
        ]
    }),
    created() {
        this.getQuestions(0)
    },
    computed: {
        ...mapGetters(['unAnsweardQuestion', 'activeTableQuestions', 'responderQuestionsPageLength', 'responderQuestionsFirstPage'])
    },
    methods: {
        ...mapActions(["updateQuestionsPagination", "getQuestions"]),
        selectionChanged() {
            this.isNotSelectedRows = !this.$refs['accounts-table'].selectedRows.length
        },
        getSelectedRow() {
            console.log(this.$refs['accounts-table'].selectedRows)
        }
    },
    watch: {
    }
};
</script>
