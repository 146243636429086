var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0"},[_c('b-button',{staticClass:"btn-icon rounded-circle",staticStyle:{"padding":".5rem 10px!important"},attrs:{"size":"sm","variant":"flat-secondary","disabled":_vm.isNotSelectedRows}},[_c('unicon',{attrs:{"name":"trash-alt","width":"18"}})],1)],1),_c('b-card-body',{staticClass:"px-0"},[_c('vue-good-table',{ref:"accounts-table",attrs:{"columns":_vm.columns,"rows":_vm.activeTableQuestions,"rtl":false,"small":true,"styleClass":"vgt-table condensed","select-options":{
                    enabled: true,
                    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'question')?_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field].substr(0, 100) + (props.formattedRow[props.column.field].length > 100 ? '...' : ''))+" ")]):(props.column.field === 'dateAsked')?_c('span',[_vm._v(" "+_vm._s(new Date(props.formattedRow[props.column.field]).toISOString().substr(0, 10))+" ")]):(props.column.field === 'action')?_c('span',[_c('b-button',{staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"2px 6px!important"},attrs:{"to":("/lessons/" + (props.row.lessonId) + "/" + (props.row.videoId) + "/question/un/" + (props.row.id)),"size":"sm","variant":"flat-secondary"}},[_c('unicon',{attrs:{"name":"ellipsis-v","width":"18"}})],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":_vm.responderQuestionsFirstPage,"total-rows":_vm.unAnsweardQuestion.length,"per-page":_vm.responderQuestionsPageLength,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return _vm.updateQuestionsPagination({currentPage: value, type: 0}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('unicon',{attrs:{"width":"20","name":"angle-right","fill":"royalblue"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('unicon',{attrs:{"width":"20","name":"angle-right","fill":"royalblue"}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }